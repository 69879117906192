import { SITE } from '../config';
import {
	AnnouncementProps,
	ClassItem,
	CourseEvent,
	FAQ,
	FooterLinkGroupProps,
	HeaderProps,
	Instructor,
	Review,
	Skillset,
} from './types';

// Header data
export const headerData: HeaderProps = {
	links: [
		{
			label: 'About',
			href: '/about',
		},
		{
			label: 'Classes',
			href: '/classes',
		},
		{
			label: 'Courses',
			href: '/courses',
		},
		{
			label: 'Workshops',
			href: '/workshops',
		},
		{
			label: 'Syllabus',
			href: '/syllabus',
		},
		{
			label: 'FAQs',
			href: '/faqs',
		},
		{
			label: 'Contact',
			href: '/contact',
		},
	],
	position: 'center',
};

// Announcement data
export const announcementData: AnnouncementProps = {
	title: 'New',
	callToAction: {
		text: 'Self-defence workshops for public-facing workers',
		linkText: 'Book training for your employees',
		href: '/workshops',
	},
};

export const heroReviewsData: string[] = [
	'A great bunch of people',
	'Exceptionally welcoming',
	'Extremely knowledgeable',
];

export const aboutTrainFeatures: any[] = [
	{
		title: 'Real-world preparedness',
		body: 'You will develop your situational awareness, allowing you to anticipate and respond to the types of dynamic threats you may face in the street.',
	},
	{
		title: 'Physiological awareness and mental fortitude',
		body: 'You will gain greater awareness of, and control over, your body; and learn how to calm your adrenaline-fuelled panic when in the face of physical danger.',
	},
	{
		title: 'Adaptability and proportionality',
		body: 'You will learn to calibrate the intensity of your response in proportion to the level of threat so that your actions remain within the limits of the law.',
	},
];

export const workshopsFeatures: any[] = [
	{
		title: 'Real-world preparedness',
		body: 'Situational awareness that allows your employees to anticipate and avoid the types of dynamic threats they may face in their workplace environment or the street.',
	},
	{
		title: 'Physiological awareness and mental fortitude',
		body: 'Greater awareness of, and control over, their own and other bodies; learning how to calm their adrenaline-fuelled panic when in the face of danger.',
	},
	{
		title: 'Adaptability and proportionality',
		body: 'The ability to calibrate the intensity of their response in proportion to the level of threat they face so their actions remain within the limits of the law.',
	},
];

// Syllabus data
export const syllabusData: Skillset[] = [
	{
		title: 'Prevention',
		description:
			"Self-defence is primarily a legal term and depending on how you handle an altercation will determine whether you lawfully defended yourself or you'll be going to prison for assault. Accordingly, you'll learn the importance of pre-emptive situational awareness, proactive de-escalation and tactical retreat.",
		skills: [
			{
				commonName: 'Threat assessment',
				traditionalName: undefined,
			},
			{
				commonName: 'Verbal de-escalation',
				traditionalName: undefined,
			},
			{
				commonName: 'Non-verbal de-escalation',
				traditionalName: undefined,
			},
			{
				commonName: 'Defensive signalling',
				traditionalName: undefined,
			},
			{
				commonName: 'Eyes on / Hands up',
				traditionalName: undefined,
			},
			{
				commonName: 'Hard target / Soft target',
				traditionalName: undefined,
			},
			{
				commonName: 'Fight / Flight / Freeze',
				traditionalName: undefined,
			},
			{
				commonName: 'Group dynamics',
				traditionalName: undefined,
			},
			{
				commonName: 'Distance management',
				traditionalName: undefined,
			},
			{
				commonName: 'Improvising obstacles',
				traditionalName: undefined,
			},
			{
				commonName: 'Pre-planning escape routes',
				traditionalName: undefined,
			},
			{
				commonName: '"Don\'t be there"',
				traditionalName: undefined,
			},
		],
	},
	{
		title: 'Striking',
		description:
			"You'll learn to remain calm under the pressure of an advancing assailant using hands, feet, knees, and elbows, to strike, unsettle and unbalance your attacker as you position yourself for a fight-ending move. You will learn the principles of mobility, distance, angle, timing, pacing and mental fortitude.",
		skills: [
			{
				commonName: 'Sparring',
				traditionalName: 'Kumite',
			},
			{
				commonName: 'Closed hand strikes',
				traditionalName: 'Tsuki waza',
			},
			{
				commonName: 'Open hand strikes',
				traditionalName: 'Atemi waza',
			},
			{
				commonName: 'Pressure point strikes',
				traditionalName: 'Atemi waza',
			},
			{
				commonName: 'Elbow strikes',
				traditionalName: 'Empi uchi',
			},
			{
				commonName: 'Blocking & Parrying',
				traditionalName: 'Uke waza',
			},
			{
				commonName: 'Front kick',
				traditionalName: 'Mae geri',
			},
			{
				commonName: 'Roundhouse kick',
				traditionalName: 'Mawashi geri',
			},
			{
				commonName: 'Side kick',
				traditionalName: 'Yoko geri',
			},
			{
				commonName: 'Back kick',
				traditionalName: 'Ushiro geri',
			},
			{
				commonName: 'Knee kick',
				traditionalName: 'Hittsui geri',
			},
		],
	},
	{
		title: 'Escapes, Locks, Chokes & Throws',
		description:
			"Extending the unbalancing skills you've learned from striking, you'll develop instinctive responses to close-quarter entanglements, learning to adjust your techniques to work on assailants of varying sizes. You will learn the principles of posture, balance, level, angle, timing and improvisation.",
		skills: [
			{
				commonName: 'Escape from head lock',
				traditionalName: 'Hakko dori',
			},
			{
				commonName: 'Escape from chokes',
				traditionalName: 'Hakko dori',
			},
			{
				commonName: 'Escape from bear hugs',
				traditionalName: 'Hakko dori',
			},
			{
				commonName: 'Shoulder lock',
				traditionalName: 'Kata gatame',
			},
			{
				commonName: 'Elbow locks',
				traditionalName: 'Hiji dori',
			},
			{
				commonName: 'Wrist locks',
				traditionalName: 'Shodan, Nidan, Tekagami',
			},
			{
				commonName: 'Redirections',
				traditionalName: 'Tai sabaki',
			},
			{
				commonName: 'Restraints',
				traditionalName: 'Taiho jitsu',
			},
			{
				commonName: 'Body throws',
				traditionalName: 'Otoshi',
			},
			{
				commonName: 'Hip throws',
				traditionalName: 'Uki Goshi',
			},
			{
				commonName: 'Leg throws',
				traditionalName: 'Kawazu gake',
			},
		],
	},
	{
		title: 'Ground-Fighting',
		description:
			"Most prolonged fights will end up with someone being knocked to the ground. Beginning with carefully controlled drills, you'll develop techniques to decisively incapacitate your assailant whether you’re on top and on your back. You will learn the principles of position, weight, control, pressure, making and taking space, timing, and precision.",
		skills: [
			{
				commonName: 'Grappling',
				traditionalName: 'Ne waza',
			},
			{
				commonName: 'Full guard',
				traditionalName: 'Do-osae',
			},
			{
				commonName: 'Half guard',
				traditionalName: 'Ashigarami',
			},
			{
				commonName: 'Open guard',
				traditionalName: 'Ashigarami',
			},
			{
				commonName: 'Butterfly guard',
				traditionalName: 'Kata guruma',
			},
			{
				commonName: 'Guard passing',
				traditionalName: 'Hairi gata',
			},
			{
				commonName: 'Side control',
				traditionalName: 'Mune gatame',
			},
			{
				commonName: 'Scarf hold',
				traditionalName: 'Kesa gatame',
			},
			{
				commonName: 'Full mount',
				traditionalName: 'Tate shiro gatame',
			},
			{
				commonName: 'Back mount',
				traditionalName: 'Ushiro gatame',
			},
			{
				commonName: 'Armbar',
				traditionalName: 'Juji gatame',
			},
			{
				commonName: 'Arm Triangle',
				traditionalName: 'Kata gatame',
			},
			{
				commonName: 'Leg Triangle',
				traditionalName: 'Sankaku jime',
			},
			{
				commonName: 'Guillotine',
				traditionalName: 'Mae hadakajime',
			},
			{
				commonName: 'Chokes & Strangles',
				traditionalName: 'Shime waza',
			},
			{
				commonName: 'Kimura',
				traditionalName: 'Gyaku ude garami',
			},
			{
				commonName: 'Americana',
				traditionalName: 'Ude garami',
			},
			{
				commonName: 'Leg locks',
				traditionalName: 'Ashi guruma',
			},
			{
				commonName: 'Sweeps',
				traditionalName: 'Hairi-kata',
			},
			{
				commonName: 'Hip escapes',
				traditionalName: 'Gyaku-ebi',
			},
			{
				commonName: 'Takedowns',
				traditionalName: 'Kuchiki taoshi',
			},
			{
				commonName: 'Back breakfall',
				traditionalName: 'Ushiro ukemi',
			},
			{
				commonName: 'Front breakfall',
				traditionalName: 'Mae ukemi',
			},
			{
				commonName: 'Side breakfall',
				traditionalName: 'Yoko ukemi',
			},
			{
				commonName: 'Forward roll',
				traditionalName: 'Mae mawari ukemi',
			},
			{
				commonName: 'Backward roll',
				traditionalName: 'Ushiro kaiten ukemi',
			},
			{
				commonName: 'Technical standup',
				traditionalName: undefined,
			},
		],
	},
	{
		title: 'Knife & Weapons Defence',
		description:
			"For those situations where you can't simply run away from an armed attack, you'll learn how to avoid taking damage as you work to subdue your assailant with the appropriate level of intensity and finality. You will learn the principles of anticipation, distance, minimising attack surface, timing, decisiveness and aggression.",
		skills: [
			{
				commonName: 'Defend knife attack to chest',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend knife attack to stomach',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend knife attack to throat',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend knife attack to back',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend knife attack with leveraging hand',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend lunging knife attack',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend downward knife attack',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend slicing knife attacks',
				traditionalName: undefined,
			},
			{
				commonName: 'Defend random knife attacks',
				traditionalName: undefined,
			},
			{
				commonName: 'Gun disarms',
				traditionalName: undefined,
			},
		],
	},
];

export const classesData: ClassItem[] = [
	{
		day: 'Sundays',
		time: '10:00–12:00',
		venue: SITE.locationAddressFull,
		price: '£40/month',
		calEventLink: 'shockwave-jujitsu',
		calEventNamespace: 'free-trial-class',
		integrationLink:
			'https://cademy.co.uk/shockwave-jujitsu/free-trial-class',
	},
];

export const typicalClassData: any[] = [
	{
		time: '10:00–10:01',
		title: 'Bow in',
		description:
			'We stand in lines (in belt order) and bow to the head instructor.',
	},
	{
		time: '10:01–10:15',
		title: 'Warm-up',
		description:
			'We run laps and then do pushups, sit-ups and squats followed by stretches and then breakfalls and rolls. You can just copy whatever the student in front of you is doing.',
	},
	{
		time: '10:15–10:45',
		title: 'Striking: Sparring',
		description:
			'We put on our gloves and leg pads and do some light (50%) sparring, changing partners to make sure we’re exposed to different bodies and styles. For your first few classes you’ll work with an instructor who will ask you about your previous experience with martial arts and provide you with gloves and pads if you don’t yet have them.',
	},
	{
		time: '10:45–11:15',
		title: 'Escapes, Locks, Chokes & Throws: Technique',
		description:
			'The instructor will demonstrate one or multiple related techniques, we will then pair up to practice the techniques whilst the instructor walks around to correct us where necessary. For your first few classes, you’ll work with an instructor who will break each technique down into small steps and drill it with you.',
	},
	{
		time: '11:15–11:40',
		title: 'Ground-fighting: Technique',
		description:
			'Starting with a demonstration of one or multiple related ground-fighting techniques, we will then pair up to drill them. The class will then end with a light (50%) ground roll. For your first few classes, you’ll roll with an instructor.',
	},
	{
		time: '11:40–11:59',
		title: 'Ground-fighting: Roll',
		description:
			'Light (50%) ground-fight sparring to test your techniques against a resisting opponent. For your first few classes, you’ll roll with an instructor.',
	},
	{
		time: '11:59–12:00',
		title: 'Bow out',
		description:
			'We stand in lines (in belt order) and bow to the head instructor.',
	},
];

// Beginners Course Details data
export const beginnersCourseDetails: {
	label: string;
	title: string;
	description: string[];
	price: string;
	priceDetail: string;
} = {
	label: 'Bring a friend for free',
	title: "4 Weeks Women's Self-Defence Course",
	description: [
		"The course is ideal if you have previously dismissed the idea of yourself as a 'martial arts person' or have been put off by the intimidating atmosphere at other dojos.",
		"No previous experience is required and you don't need to be especially fit or do any kind of physical preparation. Just come as you are and be ready to have fun with like-minded people.",
	],
	price: '£20',
	priceDetail: 'Total price for 4 weeks training',
};

// Beginners Course Features data
export const beginnersCourseFeatures: string[] = [
	'8 hours practical training with MMA coach, Terry Tatum',
	'Learn in a small class (6–8 students + 2 instructors)',
	'Sample multiple martial arts styles in a single course',
	'Increase your situational awareness and self-confidence',
	'Meet like-minded people in an encouraging environment',
	'£20 total for 4 weeks training + bring a friend for free',
];

// Beginners FAQs data
export const beginnersFaqsData: FAQ[] = [
	{
		question: "I'm not fit. Should I get in shape first?",
		answer: [
			"You don't need to be fit for this course and we would suggest a perceived lack of fitness is not a good reason to delay learning how to protect yourself. The techniques being taught do not rely on strength, speed or advanced levels of agility, rather they emphasise situational awareness and the use of physical distance, position, posture, balance and momentum.",
		],
	},
	{
		question: "I'm not very strong. Will I get injured?",
		answer: [
			"We certainly hope not. This is a beginners self-defence course and is not designed to be strenous or competitive. You won't be trying to hurt each other. You'll be training cooperatively to learn how to deal with threats out in the world. That said, this is martial arts training and for legal reasons we have to warn you there is always the risk of injury.",
		],
	},
	{
		question: 'What will I be learning? Will this stuff work?',
		answer: [
			'The lessons will simulate real-life situations where you will be taught how to identify, avoid, and safely escape from, a physical assault. The emphasis is on threat detection and both verbal and non-verbal boundary-setting. Our aim is to equip you with simple strategies and techniques that you can remember and use when under pressure.',
		],
	},
	{
		question: "I'm really uncoordinated! Will I be embarrassed?",
		answer: [
			"This is a *beginners* course. You're not expected to do things flawlessly – none of us did when we first started. We offer a non-judgemental, supportive space where you can engage in the serious play of learning how to protect yourself. You're not going to be graded or assessed so you can relax and just have fun with it all.",
		],
	},
	{
		question: "I don't want to touch or train with men. Is that okay?",
		answer: [
			"Yes, that is okay. This course is advertised as a women's course and so we can reasonably expect only women will want to participate. We do not have any female instructors, however. The instructors are men. You will be paired with another woman. You will watch the instructors demonstrate a technique and then work in your pair to practice it. There is no need for an instructor to touch you. Please contact us if you have any concerns or questions.",
		],
	},
	{
		question: "I don't know what to wear. What is appropriate?",
		answer: [
			'Whatever you would normally wear to the gym or to yoga: loose/stretchy clothing e.g. a pair of leggings or tracksuit bottoms and a comfy t-shirt. Specialised footwear is not required – indoor trainers are fine though most people train in bare feet or in grippy socks.',
		],
	},
];

// Beginners Course Dates
export const beginnersCourseEvents: CourseEvent[] = [
	// {
	// 	title: '4 Weeks Beginners Course',
	// 	month: 'July 2024',
	// 	dates: [
	// 		{
	// 			month: 'Jul',
	// 			day: '07',
	// 		},
	// 		{
	// 			month: 'Jul',
	// 			day: '14',
	// 		},
	// 		{
	// 			month: 'Jul',
	// 			day: '21',
	// 		},
	// 		{
	// 			month: 'Jul',
	// 			day: '28',
	// 		},
	// 	],
	// 	days: 'Sundays',
	// 	times: '10:00–12:00',
	// 	venue: SITE.locationAddressShort,
	// 	price: '£20',
	// 	integrationLink:
	// 		'https://cademy.co.uk/shockwave-jujitsu/4-weeks-beginners-course',
	// },
	// {
	// 	title: "4 Weeks Women's Self-Defence Course",
	// 	month: 'September 2024',
	// 	dates: [
	// 		{
	// 			month: 'Sep',
	// 			day: '08',
	// 		},
	// 		{
	// 			month: 'Sep',
	// 			day: '15',
	// 		},
	// 		{
	// 			month: 'Sep',
	// 			day: '22',
	// 		},
	// 		{
	// 			month: 'Sep',
	// 			day: '29',
	// 		},
	// 	],
	// 	days: 'Sundays',
	// 	times: '10:00–12:00',
	// 	venue: SITE.locationAddressShort,
	// 	price: '£20',
	// 	calEventNamespace:
	// 		'womens-self-defence-course-september-2024?month=2024-09',
	// 	integrationLink:
	// 		'https://cademy.co.uk/shockwave-jujitsu/4-weeks-womens-self-defence-course',
	// },
	{
		title: "4 Weeks Women's Self-Defence Course",
		month: 'November 2024',
		dates: [
			{
				month: 'Nov',
				day: '03',
			},
			{
				month: 'Nov',
				day: '10',
			},
			{
				month: 'Nov',
				day: '17',
			},
			{
				month: 'Nov',
				day: '24',
			},
		],
		days: 'Sundays',
		times: '10:00–12:00',
		venue: SITE.locationAddressShort,
		price: '£20',
		calEventLink: 'shockwavejujitsu',
		calEventNamespace:
			'womens-self-defence-course-november-2024?month=2024-11',
		integrationLink:
			'https://cademy.co.uk/shockwave-jujitsu/4-weeks-womens-self-defence-course',
	},
	{
		title: "4 Weeks Women's Self-Defence Course",
		month: 'January 2025',
		dates: [
			{
				month: 'Jan',
				day: '05',
			},
			{
				month: 'Jan',
				day: '12',
			},
			{
				month: 'Jan',
				day: '19',
			},
			{
				month: 'Jan',
				day: '26',
			},
		],
		days: 'Sundays',
		times: '10:00–12:00',
		venue: SITE.locationAddressShort,
		price: '£20',
		calEventLink: 'shockwavejujitsu',
		calEventNamespace:
			'womens-self-defence-course-january-2025?month=2025-01',
		integrationLink:
			'https://cademy.co.uk/shockwave-jujitsu/4-weeks-womens-self-defence-course',
	},
	{
		title: "4 Weeks Women's Self-Defence Course",
		month: 'March 2025',
		dates: [
			{
				month: 'Mar',
				day: '09',
			},
			{
				month: 'Mar',
				day: '16',
			},
			{
				month: 'Mar',
				day: '23',
			},
			{
				month: 'Mar',
				day: '30',
			},
		],
		days: 'Sundays',
		times: '10:00–12:00',
		venue: SITE.locationAddressShort,
		price: '£20',
		calEventLink: 'shockwavejujitsu',
		calEventNamespace:
			'womens-self-defence-course-march-2025?month=2025-03',
		integrationLink:
			'https://cademy.co.uk/shockwave-jujitsu/4-weeks-womens-self-defence-course',
	},
	{
		title: "4 Weeks Women's Self-Defence Course",
		month: 'May 2025',
		dates: [
			{
				month: 'May',
				day: '04',
			},
			{
				month: 'May',
				day: '11',
			},
			{
				month: 'May',
				day: '18',
			},
			{
				month: 'May',
				day: '25',
			},
		],
		days: 'Sundays',
		times: '10:00–12:00',
		venue: SITE.locationAddressShort,
		price: '£20',
		calEventLink: 'shockwavejujitsu',
		calEventNamespace: 'womens-self-defence-course-may-2025?month=2025-05',
		integrationLink:
			'https://cademy.co.uk/shockwave-jujitsu/4-weeks-womens-self-defence-course',
	},
];

// Workshop worker types data
export const workerTypes: string[] = [
	'Teachers',
	'Doctors',
	'Nurses',
	'Paramedics',
	'Carers',
	'Social Workers',
	'Recovery Workers',
	'Shop Workers',
	'Hospitality Workers',
	'Transport Workers',
	'Security Workers',
	'Event Stewards',
];

// FAQs data
export const faqsData: FAQ[] = [
	{
		question: 'Will I get beaten up?',
		answer: [
			'No! We have a no bullies/no egos rule. None of us are show-offs trying to impress each other. Plus, we train only at 50% when sparring. Going slow enables us to concentrate on the application of good technique rather than relying on adrenaline and brute strength.',
			'The goal is to drill the same techniques over and over until they become instinctive responses. Accordingly, we are hyperconscious of preventing injuries so that students can maintain their training frequency over time. That said, this is martial arts training and there is always the risk of injury.',
			'Whilst we cannot guarantee you won’t get injured, we can promise that you won’t feel as though you’ve been roughed up.',
		],
	},
	{
		question: 'Do I need any prior experience in Martial Arts?',
		answer: [
			'No prior experience in martial arts is needed. Most people who join our classes have never done a martial art before.',
			'As a beginner, you will be paired with an instructor who will guide you through your first classes. You won’t ever be left alone wondering what to do or who to work with.',
		],
	},
	{
		question: 'Do you offer female-only classes?',
		answer: [
			'We offer regular women’s self-defence courses but our weekly classes are mixed. The simple fact is, as a woman, you are much more likely to be attacked by a man, and so to prepare you for this we think you should train with both men and women. If you were to train only with women you would be denying yourself the opportunity to test your techniques on larger and heavier bodies which ultimately could leave you with a false sense of confidence in the effectiveness of your skills.',
		],
	},
	{
		question: 'Do you offer a free trial class?',
		answer: [
			'Yes, we honor the martial arts tradition of offering a free trial class. We also offer regular beginners courses which are ideal for sampling our full syllabus.',
			'As a beginner, you will be paired with an instructor who will guide you through your first classes. You won’t ever be left alone wondering what to do or who to work with.',
		],
	},
	{
		question: 'What should I wear? Do I need any equipment?',
		answer: [
			'New students can just turn up in loose clothing (a pair of tracksuit bottoms and a T-shirt). Specialised footwear is not required – indoor trainers are perfectly fine though most students train in bare feet or in grippy socks.',
			"In your first few classes you will be provided with light boxing gloves and leg pads to use for light standup sparring. Over time you will want to purchase your own MMA gloves, leg pads and perhaps a gum shield. Once you've become an established member, you might also want to purchase a Shockwave Jujitsu T-shirt.",
		],
	},
	{
		question: 'What is Jujitsu?',
		answer: [
			'Jujitsu “joo-jit-sue” (also known as Jujutsu, Ju-Jitsu and Jiu-Jitsu) is an ancient Japanese martial art which is often described as the mother of all Japanese martial arts as many other systems originated from Ju-Jitsu, e.g. the founder of Judo (Jigoro Kano) was a Jujitsu master who saw the potential for a sport in Jujitsu and came up with Kodokan Judo. Aikido also stemmed from an old Jujitsu school.',
			'Although Jujitsu has older roots, it is most commonly associated with the methods of unarmed combat which the Samurai would use in feudal Japan when they were disarmed in battle. To someone with no experience of Jujitsu it is best described as an “all round” martial art as it has everything that a lot of other arts have and much more: punching, kicking, throws, locks and joint manipulation, chokes, grappling and ground-fighting are all practiced in the art of Jujitsu.',
		],
	},
	{
		question: 'Is Jujitsu effective in a real fight?',
		answer: [
			'Whilst no self-defence system can guarantee 100% safety in every altercation, Jujitsu offers a wide range of practical tools that can be relied upon to tip the balance in your favor.',
			'Many martial arts will teach a lot of complicated strikes and high kicks which would be impossible to actually use if one was attacked on the street. In Jujitsu, students develop instinctive responses against a wide variety of realistic attacks – rear attacks, holds, knives/weapons, punches, kicks, attacks on the ground and many more – but crucially, always with an eye towards preventative deescalation or early escape should that fail.',
			'Also, because size and strength is not a major factor in Jujitsu, its techniques often form the basis of self-defence courses offered to women.',
			'Ultimately, beyond techniques, the effectiveness of Jujitsu depends upon your mental resilience and your commitment to regular training which will hone your ability to assess threats under pressure, react appropriately, and significantly reduce the chances of you being caught off guard.',
		],
	},
	{
		question: 'Do you teach MMA (Mixed Martial Arts)?',
		answer: [
			'We teach mixed martial arts for self-defence rather than for competition. Our instructors have backgrounds in Karate, Judo, Brazilian Jiu-Jitsu, Kickboxing and other fighting styles. Accordingly, our mixed martial arts syllabus combines these styles into a cohesive whole so that you learn from a variety of arts and not just one narrow selection of techniques that might lose their edge in any given scenario.',
			'Students first learn the principles and concepts informing the syllabus and can then explore what techniques work best for them based on successes and failures discovered whilst testing themselves against different training partners. The goal is develop students who can problem-solve in real-time and who can rapidly adapt to different assailants and changing circumstances.',
		],
	},
	{
		question: 'Do I need to be aggressive?',
		answer: [
			'You need to know how to apply your aggression judiciously. Our perspective is the avoidance of a physical confrontation is preferred but if that is impossible or unsafe, you need to value your life highly enough that you would immediately retaliate to preserve it. Hesitation can be fatal and so the goal of regular training is to develop instinctive responses in direct proportion to the level of threat.',
			'Because normal brain function is significantly impaired when under stress, every class includes sparring (standup striking and/or ground-fighting) where your skills can be pressure-tested against a resisting opponent. This is vital practice in retaining your composure whilst on the receiving end of aggression at the same time as learning how to temper your own aggression in the heat of the moment.',
		],
	},
	{
		question:
			'What is the difference between Martial Arts and Self-Defence?',
		answer: [
			'Martial Arts is broad term for a variety of traditions and applications. In terms of training as a civilian, a clear distinction can be made between combat sports and self-defence.',
			'Self-defence training is about avoiding fights and responding with force only when conflict cannot be avoided. Combat sports are about initiating a fight with a willing opponent.',
			'Whereas self-defence is about deescalation and the minimum application of force, combat sports are about escalation towards maximal application of force until victory is achieved.',
			'A more sobering way of looking at it is: in combat sports two competitors share the same skillset, body weight, and a referee who enforces rules to ensure a fair fight. In self-defence situations there is no referee, there are no rules, no weight parity, no prohibition on the use of weapons and no limit on the number of assailants you may be up against.',
		],
	},
	{
		question: 'Do you grade students?',
		answer: [
			'Yes. Students are graded regularly and, if successful, are awarded with a belt and certificate to honour their progress.',
		],
	},
	{
		question: 'If I have a medical condition, can I still train?',
		answer: [
			'As long as the instructors are made fully aware of your condition, and both you and the instructors know how much (if at all) it limits your training, then you will be fine. Any medication needed, e.g. inhalers, insulin, etc., should be brought to each training session. In some special cases, a doctors note may be required to show you are fit to train.',
		],
	},
	{
		question: 'Do I need to be fit to train?',
		answer: [
			'A high level of fitness is not important in Jujitsu training. People do join the class a way of improving their cardiovascular fitness and learning something useful in the process.',
		],
	},
];

// Instructors data
export const instructorsData: Instructor[] = [
	{
		name: 'Terry Tatum',
		role: 'Head Instructor',
		imageUrl: '/instructors/terry-tatum.jpg',
		belt: {
			count: 3,
			type: 'black',
		},
	},
	{
		name: 'Marc Godden',
		role: 'Instructor',
		imageUrl: '/instructors/marc-godden.jpg',
		belt: {
			count: 1,
			type: 'black',
		},
	},
	// {
	// 	name: 'Adam Crowe',
	// 	role: 'Instructor',
	// 	imageUrl: '/instructors/adam-crowe.jpg',
	// 	belt: {
	// 		count: 1,
	// 		type: 'brown-black',
	// 	},
	// },
	{
		name: 'Ali Malik',
		role: 'Instructor',
		imageUrl: '/instructors/ali-malik.jpg',
		belt: {
			count: 1,
			type: 'brown-black',
		},
	},
];

// Reviews data
export const reviewsData: Review[] = [
	{
		title: 'A great bunch of people',
		body: 'I started training at Shockwave Jujitsu as an alternative way to improve my fitness. Not only has my fitness level increased, my confidence has grown significantly. Whether you are a beginner or already at an advanced level, Shockwave Jujitsu will hone your skills and reward you with a sense of achievement, all whilst training in a relaxed environment with a great bunch of people.',
		rating: 5,
		author: {
			name: 'Marc Godden',
			imageUrl: '/instructors/marc-godden.jpg',
		},
	},
	{
		title: 'Instructors are extremely knowledgeable',
		body: 'I really enjoy challenging myself at Shockwave Ju-Jitsu. I always look forward to getting down on a Sunday and training with like-minded people. The instructors are extremely knowledgeable and approachable.',
		rating: 5,
		author: {
			name: 'Richie Garrod',
			imageUrl: null,
		},
	},
	{
		title: 'Friendly place to learn',
		body: 'Excellent self defence training by 3rd Dan Black Belt Sensei Terry Tatum. Perfect for anyone looking for a friendly place to learn how to protect themselves on the streets using jujitsu.',
		rating: 5,
		author: {
			name: 'Ali Malik',
			imageUrl: null,
		},
	},
	{
		title: 'All levels of ability are catered for',
		body: 'I have been training with Shockwave for the last four or five months now. Shockwave offers a unique approach to self defence whereby you are able to gain belt gradings in Jiu-Jitsu as you improve, and you are also able to develop relevant skills in striking and grappling week by week. The syllabus also includes how to defend against single, multiple, and armed assailants, and provides you with a great opportunity to build self-confidence. Everyone has been exceptionally welcoming, and all levels of ability are catered for.',
		rating: 5,
		author: {
			name: 'Liam Connolly',
			imageUrl: null,
		},
	},
	{
		title: 'Defend yourself and your loved ones',
		body: 'I joined a few months back and, being a foreigner, I didn’t expect much, but I was pleasantly surprised by the level of training and support I received so far. Instructors are very knowledgeable and patient as they try to find an individual approach for every student. The overall atmosphere during the classes is very friendly and you feel like you achieved a lot after every session. This is an awesome way to keep fit and improve your confidence as well as an excellent opportunity to gain essential skills to defend yourself and your loved ones if needed.',
		rating: 5,
		author: {
			name: 'Vladimir Muzichuk',
			imageUrl: null,
		},
	},
	{
		title: 'Seriously impressed by their mixed martial arts syllabus',
		body: 'If you`re looking to get back into martial arts and want to focus on developing practical self-defence skills rather than competing in tournaments, Shockwave Jujitsu is the ideal place to be. I found them through a Google search for martial arts clubs in Ipswich and was seriously impressed by the breadth of their mixed martial arts syllabus that offers a wide range of techniques to keep things fresh and exciting. Fast forward 8 years, and I`m still hooked and learning something new every week.',
		rating: 5,
		author: {
			name: 'Adam Crowe',
			imageUrl: null,
		},
	},
	{
		title: 'Being a female I feel very safe and comfortable training with the group',
		body: 'All instructors have a high level of knowledge and the classes are both informative and enjoyable. Being a female I feel very safe and comfortable training with the group. I have learnt so much and feel that my confidence has grown. I would highly recommend joining!',
		rating: 5,
		author: {
			name: 'Teri Welham',
			imageUrl: null,
		},
	},
];

// Footer: Info
export const footerLinkGroupInfoData: FooterLinkGroupProps[] = [
	{
		title: 'Info',
		items: [
			{
				title: 'About',
				href: '/about',
			},
			{
				title: 'Syllabus',
				href: '/syllabus',
			},
			{
				title: 'Reviews',
				href: '/reviews',
			},
		],
	},
];

// Footer: Join
export const footerLinkGroupTrainData: FooterLinkGroupProps[] = [
	{
		title: 'Join',
		items: [
			{
				title: 'Classes',
				href: '/classes',
			},
			{
				title: 'Courses',
				href: '/courses',
			},
			{
				title: 'Workshops',
				href: '/workshops',
			},
		],
	},
];

// Footer: Support
export const footerLinkGroupHelpData: FooterLinkGroupProps[] = [
	{
		title: 'Support',
		items: [
			{
				title: 'FAQs',
				href: '/faqs',
			},
			{
				title: 'Contact',
				href: '/contact',
			},
		],
	},
];

// Footer: Legal
export const footerLinkGroupLegalData: FooterLinkGroupProps[] = [
	{
		title: 'Legal',
		items: [
			{
				title: 'Terms of Use',
				href: '/terms',
			},
			{
				title: 'Privacy Policy',
				href: '/privacy',
			},
		],
	},
];
